import { Checkbox, Label, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components';

export const AuthWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
`;

export const AuthBackground = styled.div`
  background-color: #4d58c6;
  background-image: url('/background.jpg');
  background-size: cover;
  position: absolute;
  background-position: center right;
  width: 60%;
  left: 40%;
  height: 100%;
  z-index: 0;
`;

export const AuthContainer = styled.div`
  width: 45%;
  display: flex;
  background-color: ${setColorTheme('grey800')};
  border-radius: 2.4rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 9.5rem 10% 3rem 10%;
  flex-direction: column;
  z-index: 1;
  position: relative;

  @media (min-resolution: 1.5dppx) {
    padding: 6.2rem 10%;
  }
`;

export const AuthLogo = styled.div`
  display: flex;
  align-items: center;
  color: ${setColorTheme('grey700')};
  font-weight: 600;

  > h1 {
    padding-left: 2.4rem;
  }
`;

export const AuthTitle = styled(Label)`
  margin: 10.1rem 0 3.6rem;

  @media (min-resolution: 1.5dppx) {
    margin: 3.2rem 0 2.1rem;
  }
`;

export const AuthContent = styled.div`
  margin: 4.6rem 0 3.2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AuthField = styled.fieldset`
  margin-bottom: 3rem;
`;

export const AuthError = styled.p`
  padding: 1rem 0;
  color: ${setColorTheme('error400')};
`;

export const AuthCheckbox = styled(Checkbox)`
  margin-bottom: 2rem;
`;

export const AuthButtons = styled.div`
  display: flex;
  align-items: flex-end;
  align-content: flex-end;

  > button {
    margin-left: auto;
  }
`;
