import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { TenantTag } from '@cs/state/model';
import { addTenantTag, removeTenantTag } from '@cs/state/mutations';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';

type UseTagsTenantOptions = {
  refetch: () => void;
  tagsSelected?: TenantTag[];
};

export function useTagsTenant(tenantId: string, options: UseTagsTenantOptions) {
  const { t } = useTranslation();

  const { toastManager } = useToast();

  const [addTag] = useMutation(addTenantTag);
  const [removeTag] = useMutation(removeTenantTag);

  const tagSelected = useMemo(
    () =>
      options.tagsSelected
        ? options.tagsSelected.length
          ? options.tagsSelected[0].tag
          : undefined
        : undefined,
    [options.tagsSelected],
  );

  const handleCheckTenant = async (tag: string) => {
    if (tagSelected) {
      await removeTag({ variables: { tag: tagSelected, tenantId } });
    }

    await addTag({
      variables: { tag: { tag, tenant_id: tenantId } },
      onCompleted: () => {
        options.refetch();
        toastManager({
          type: 'success',
          message: t('Tag assigned correctly'),
          testId: 'toast-update-tenant',
        });
      },
    });
  };

  return {
    handleCheckTenant,
    tagSelected,
  };
}
