/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { MaxItemsPag } from '@cs/state/constants';
import { listUsers } from '@cs/state/queries';
import { usePaginate } from './usePaginate';

export function useUsersPaginate(clientId: string) {
  const {
    search,
    changePage,
    offset,
    handleSearch,
    currentPage,
    getQueryVariables,
  } = usePaginate();

  const { loading, data, error, refetch } = useQuery(listUsers, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...getQueryVariables(),
      clientId,
      offset: offset,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const users = useMemo(
    () => (data ? data.users.map((item: any) => ({ ...item.user })) : []),
    [data],
  );
  const total = useMemo(
    () => (data ? data.users_aggregate.aggregate.count : 0),
    [data],
  );

  const totalPages = useMemo(
    () => Math.ceil(total / MaxItemsPag),
    [offset, MaxItemsPag, total],
  );

  const updateUsers = () => refetch(getQueryVariables());

  return {
    data,
    users,
    handleSearch,
    total,
    currentPage,
    totalPages,
    changePage,
    variables: getQueryVariables(),
    updateUsers,
    usersError: error,
    search,
    loading,
  };
}
