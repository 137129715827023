import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { AuthProvider } from '@cs/state/model';
import { getAuthProvider } from '@cs/state/queries';

export function useAuthProvider() {
  const { data, loading } = useQuery<{ providers: AuthProvider[] }>(
    getAuthProvider,
  );

  const providers = useMemo(
    () =>
      data
        ? data.providers.map((item) => ({ value: item.name, name: item.name }))
        : [],
    [data],
  );

  return {
    providers,
    loading,
  };
}
