export type listRolesDto = {
  roles: RoleDto[];
};

export type listResourcesDto = {
  resources: ResourceDto[];
};

export type listActionsDto = {
  actions: ActionDto[];
};

export type listUserRoleDto = {
  role: UserRoleDto[];
};

export type UserRoleDto = {
  role: string;
  role_id: string;
};

export type ResourceDto = {
  name: string;
};

export type ActionDto = {
  name: string;
};

export type RolePermissionsDto = {
  action: string;
  resource: string;
};

export type RoleDto = {
  name: string;
  id: string;
  permissions: RolePermissionsDto[];
};

export enum Roles {
  Admin = 'ADMIN',
  User = 'USER',
  Business = 'BUSINESS',
  Support = 'SUPPORT',
}

export enum SCOPES {
  canViewClients = 'canViewClients',
  canViewUsers = 'canViewUsers',
  canViewTenants = 'canViewTenants',
  canEditTenantName = 'canEditTenantName',
  canDeleteLicense = 'canDeleteLicense',
  canViewLicenses = 'canViewLicences',
  canViewApiKeys = 'canViewApiKeys',
  canViewServices = 'canViewServices',
  canWriteServices = 'canWriteServices',
  canDeleteServices = 'canDeleteServices',
  canWriteClients = 'canWriteClients',
  canWriteUsers = 'canWriteUsers',
  canWriteTenants = 'canWriteTenants',
  canReadLicenses = 'canReadLicenses',
  canWriteLicenses = 'canWriteLicences',
  canWriteApiKeys = 'canWriteApiKeys',
  canDeleteClients = 'canDeleteClients',
  canViewCentralServicesApiKeys = 'canViewCentralServicesApiKeys',
  canListRequests = 'canListRequests',
  canEditClients = 'canEditClients',
  canRequestLicense = 'canRequestLicense',
}

export const PERMISSIONS = {
  [Roles.Admin]: [
    SCOPES.canViewClients,
    SCOPES.canViewUsers,
    SCOPES.canViewTenants,
    SCOPES.canViewLicenses,
    SCOPES.canViewApiKeys,
    SCOPES.canViewServices,
    SCOPES.canWriteServices,
    SCOPES.canDeleteServices,
    SCOPES.canWriteClients,
    SCOPES.canWriteUsers,
    SCOPES.canViewLicenses,
    SCOPES.canWriteTenants,
    SCOPES.canWriteLicenses,
    SCOPES.canWriteApiKeys,
    SCOPES.canDeleteClients,
    SCOPES.canWriteClients,
    SCOPES.canViewCentralServicesApiKeys,
    SCOPES.canRequestLicense,
    SCOPES.canListRequests,
    SCOPES.canDeleteLicense,
    SCOPES.canEditTenantName,
  ],
  [Roles.User]: [
    SCOPES.canViewClients,
    SCOPES.canViewUsers,
    SCOPES.canViewTenants,
    SCOPES.canViewLicenses,
    SCOPES.canViewApiKeys,
    SCOPES.canViewServices,
    SCOPES.canWriteServices,
    SCOPES.canDeleteServices,
    SCOPES.canEditClients,
    SCOPES.canWriteClients,
    SCOPES.canWriteUsers,
    SCOPES.canWriteTenants,
    SCOPES.canWriteLicenses,
    SCOPES.canWriteApiKeys,
    SCOPES.canDeleteClients,
    SCOPES.canWriteClients,
    SCOPES.canViewCentralServicesApiKeys,
    SCOPES.canRequestLicense,
    SCOPES.canListRequests,
    SCOPES.canDeleteLicense,
    SCOPES.canEditTenantName,
  ],
  [Roles.Support]: [
    SCOPES.canViewClients,
    SCOPES.canViewServices,
    SCOPES.canViewLicenses,
    SCOPES.canWriteLicenses,
    SCOPES.canViewServices,
    SCOPES.canWriteServices,
    SCOPES.canDeleteServices,
  ],
  [Roles.Business]: [
    SCOPES.canViewClients,
    SCOPES.canViewUsers,
    SCOPES.canViewTenants,
    SCOPES.canWriteUsers,
    SCOPES.canWriteTenants,
    SCOPES.canRequestLicense,
  ],
};
