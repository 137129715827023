import { EmptyState, Chips } from '@facephi/ui-react';
import styled from 'styled-components';

export const CardEmptyState = styled(EmptyState)`
  height: 100%;
  align-items: center;

  p {
    text-align: center;
  }
`;

export const StyledChips = styled(Chips)`
  :disabled {
    color: var(--UI--colors-primary500);
  }
`;
