import { useEffect } from 'react';
import { useRoles } from '@cs/hooks';
import { Dropdown } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';

type IProps = {
  resource?: string;
  value?: string;
  onChange(value: string): void;
};

export function DropdownAction({ resource, value, onChange }: IProps) {
  const { actions, listActions } = useRoles();
  const { t } = useTranslation();

  useEffect(() => {
    if (resource) {
      listActions(resource);
    }
  }, [resource]);

  return (
    <Dropdown
      options={actions}
      label={t('Actions')}
      value={value}
      placeholder={t('Select a action')}
      onChange={onChange}
    />
  );
}
