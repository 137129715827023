import { Modal, FlexContainer, Label } from '@facephi/ui-react';
import styled from 'styled-components';

export const StyledModalComment = styled(Modal)`
  width: 80rem;
  height: 30rem;
`;

export const StyledModalDetail = styled(Modal)`
  width: 80rem;
  height: 80rem;
`;

export const StyledModalContent = styled(FlexContainer)`
  margin: 2rem;

  textarea {
    height: 10rem;
  }
`;

export const StyledLabelBold = styled(Label)`
  font-weight: 800;
  margin-top: 2rem;
`;
