import { useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  listRolesDto,
  listResourcesDto,
  listActionsDto,
} from '@cs/state/model';
import { assignRole } from '@cs/state/mutations';
import {
  getRoles,
  getResources,
  getActionsByResource,
} from '@cs/state/queries';
import { DropdownSearchOption } from '@facephi/ui-react';

export function useRoles() {
  const [getRolesTenant, { loading, data: roles }] =
    useLazyQuery<listRolesDto>(getRoles);

  const [loadResources, { data: dataResources }] =
    useLazyQuery<listResourcesDto>(getResources);

  const [loadActions, { data: dataActions }] =
    useLazyQuery<listActionsDto>(getActionsByResource);

  const [addRole] = useMutation(assignRole);

  const listRoles = async (tenantName: string, clientId: string) =>
    await getRolesTenant({
      variables: {
        clientId: clientId,
        tenantName: tenantName,
      },
    });

  const listResources = async () => await loadResources();
  const listActions = async (resource: string) =>
    await loadActions({ variables: { resource } });

  const resources = useMemo(
    () =>
      dataResources
        ? dataResources.resources.map((item) => ({
            value: item.name,
            name: item.name,
          }))
        : [],
    [dataResources],
  );

  const actions = useMemo(
    () =>
      dataActions
        ? dataActions.actions.map((item) => ({
            value: item.name,
            name: item.name,
          }))
        : [],
    [dataActions],
  );

  const assignRoleDefault = async (
    tenants: DropdownSearchOption[],
    clientId: string,
    userId: string,
  ) => {
    await Promise.all(
      tenants.map(async (tenant) => {
        const { data } = await getRolesTenant({
          variables: {
            clientId: clientId,
            tenantName: tenant.name,
          },
        });

        const roles = data?.roles;

        if (roles) {
          if (roles.length) {
            const roleDefault = roles?.find(
              (item) => item.name === 'OPERATIONS',
            );

            if (roleDefault) {
              addRole({
                variables: {
                  role: {
                    role: roleDefault.name,
                    role_id: roleDefault.id,
                    tenant_id: tenant.value,
                    user_id: userId,
                  },
                },
              });
            }
          }
        }
      }),
    );
  };

  return {
    listRoles,
    listResources,
    listActions,
    roles,
    loading,
    assignRoleDefault,
    resources,
    actions,
  };
}
