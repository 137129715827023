import Chrome from '@cs/assets/img/chrome.png';
import Edge from '@cs/assets/img/edge.png';
import Mozilla from '@cs/assets/img/firefox.png';
import Safari from '@cs/assets/img/safari.png';
import { TypeFiles } from '@facephi/ui-react';
import { AppsIconsType } from './model';
import { BrowserListProps } from './model/browser';

export const USERNAME = 'username';
export const PASSWORD = 'password';
export const EMAIL = 'email';
export const NAME = 'name';
export const SLUG = 'slug';
export const ID = 'id';
export const COMPONENTS = 'components';
export const SURNAME = 'surname';
export const DESCRIPTION = 'description';
export const ENDDATE = 'endDate';
export const LICENSETYPE = 'licenseType';
export const LICENSE = 'license';
export const SERVICE = 'service';
export const SERVICE_NAME = 'serviceName';
export const ORIGIN = 'origin';
export const ORIGINS = 'origins';
export const BUNDLE_ID = 'bundleId';
export const FIRSTNAME = 'firstName';
export const LASTNAME = 'lastName';
export const AUTH_ID = 'authId';
export const ENABLE_VIDEO = 'enable_video';
export const JUMIO_ENVIRONMENT = 'jumioEnvironment';
export const SECURITY_PROVIDER = 'securityProvider';
export const ANTIFRAUD = 'antifraud';
export const LIVENESS = 'liveness';
export const SECURITY = 'security';
export const ENABLE_AUTOMATIC_SCAN = 'enable_automatic_scan';
export const AUTOMATIC_SCAN = 'automatic_scan';
export const SECURITY_CONFIG = 'securityConfig';
export const PLATFORM = 'platform';
export const COUNTRY = 'country';
export const TIMEZONE = 'timezone';
export const LANGUAGE = 'language';
export const SELPHI_SDK = 'selphid_sdk';
export const ADVANCED_TRACKING = 'advanced_tracking';
export const TIMEOUT_IMAGES = 'timeout_images';
export const PERMISSIONS = 'permissions';
export const AUTH_PROVIDER = 'auth_provider';

// Validations
export const NAME_VALIDATION = 'Name is a required field';
export const SLUG_VALIDATION = 'The format of the slug field is invalid';
export const USERNAME_VALIDATION =
  'The format of the username field is invalid';
export const PASSWORD_VALIDATION = 'Password is a required field';
export const EMAIL_VALIDATION = 'Email is a required field';
export const SURNAME_VALIDATION = 'Surname is a required field';
export const EMAIL_INVALID_VALIDATION = 'Invalid email format';
export const START_DATE_VALIDATION = 'Start date is a required field';
export const END_DATE_VALIDATION = 'End date is a required field';
export const END_AFTER_START_VALIDATION = 'End date must be after than today';
export const TYPE_VALIDATION = 'Type is a required field';
export const LICENSE_VALIDATION = 'License is a required field';
export const REQUIRED_VALIDATION = 'This is a required field';
export const BUNDLEID_VALIDATION = 'Bundle id is a required field';
export const FINGERPRINT_VALIDATION = 'Fingerprint is a required field';
export const SERVICES_VALIDATION = 'Services is a required field';
export const FORMAT_VALIDATION = 'The format is invalid';

export enum RoutesName {
  profile = '/profile',
  userId = '/clients/:clientId/users/:userId',
  tenantId = '/clients/:clientId/tenants/:tenantId',
  servicesId = '/clients/:clientId/services/:serviceId',
  licensesByService = '/clients/:clientId/services/:serviceId/licenses',
  licenseByService = '/clients/:clientId/services/:serviceId/licenses/:licenseId',
  clients = '/clients',
  roles = '/roles',
  clientId = '/clients/:id/:type',
  clientProfileId = '/clients/profile/:id',
  licenses = '/licenses',
  licenseId = '/licenses/:licenseId',
  error = '/error',
  apiKeys = '/apiKeys',
  licenseRequest = '/licenses/request',
  requests = '/requests',
}

// Least common multiple for grid of 6, 5, 4 and 3
export const MaxItemsPag = 9;

export const browserList: BrowserListProps[] = [
  {
    id: 1,
    image: Chrome,
    name: 'Chrome',
    version: '90.0.0',
    route: 'https://www.google.com/chrome/',
    installable: true,
  },
  {
    id: 1,
    image: Chrome,
    name: 'Chrome Headless',
    version: '90.0.0',
    route: 'https://www.google.com/chrome/',
    installable: false,
  },
  {
    id: 2,
    image: Mozilla,
    name: 'Firefox',
    version: '90.0.0',
    route: 'https://www.mozilla.org/es-ES/firefox/new/',
    installable: true,
  },
  {
    id: 3,
    image: Edge,
    name: 'Edge',
    version: '90.0.0',
    route: 'https://www.microsoft.com/es-es/edge',
    installable: true,
  },
  {
    id: 4,
    image: Safari,
    name: 'Safari',
    version: '14.1.0',
    route: 'https://support.apple.com/es_ES/downloads/safari',
    installable: true,
  },
];

export enum AppName {
  operations = 'Operations',
  studio = 'Studio',
  ux = 'UX',
  antiFraud = 'Anti fraud',
  privacy = 'Privacy',
  ai = 'AI',
}

export const AppsWithIcon: AppsIconsType[] = [
  {
    iconName: 'ChartPie',
    color: 'yellow',
    label: AppName.operations,
  },
  {
    iconName: 'PencilCircle',
    color: 'pink',
    label: AppName.studio,
  },
  {
    iconName: 'Smiley',
    color: 'blue',
    label: AppName.ux,
  },
  {
    iconName: 'Lock',
    color: 'skyBlue',
    label: AppName.privacy,
  },
  {
    iconName: 'Brain',
    color: 'green',
    label: AppName.ai,
  },
  {
    iconName: 'FraudIcon',
    color: 'tomato',
    label: AppName.antiFraud,
  },
];

export enum CodeStatus {
  error404 = '404',
  error500 = '500',
}

export const DEFAULT_FORMAT_DATE = 'DD/MM/YY HH:mm:ss';
export const DEFAULT_TIMEZONE = 'Europe/Madrid';

export const AcceptedFiles = [
  TypeFiles.jpg,
  TypeFiles.jpeg,
  TypeFiles.png,
  TypeFiles.webp,
];

export const MaxSizeImage = 10485760;

export const ASIDENAV_WIDTH = '9rem';

export enum Hasura {
  claims = 'https://hasura.io/jwt/claims',
  allowedRoles = 'x-hasura-allowed-roles',
  defaultRole = 'x-hasura-default-role',
}

export const URL_EXP =
  /^(https?:\/\/(?:[\w*-]+\.)*[\w-]+(?:\.[a-z]{2,})?(?::\d{2,5})?)/;
