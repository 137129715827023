import { Label, Modal } from '@facephi/ui-react';
import styled from 'styled-components';

export const ModalLogOutStyles = styled(Modal)`
  width: 42rem;
  height: 41.5rem;

  img {
    width: 25rem;
    height: 15.5rem;
  }
`;

export const ModalLogOutLabel = styled(Label)`
  margin: 3.2rem 0 0.4rem;
`;
