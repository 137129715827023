import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

@font-face {
    font-family: 'Poppins';
    src: url('/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  html {
    font-size: 62.5%;
    min-height: 100%;
    scroll-behavior: smooth;
    height: 100%;
  }

  body {
    line-height: 1;
    height: 100%;
  }

  #root {
    display: flex;
    min-height: 100%;
    width: 100%;
  }

  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  a {
    text-decoration: none;
  }
  body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    background-color: #ffffff;
  }

  input, button {
    font-family: 'Poppins', sans-serif;
  }
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
    {
        display: none;
    }

  strong {
    font-weight: 600;
  }

  * {
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 2px;
      box-shadow: none;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b3bdcd;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  /* animation when the theme change */
  *,
  *::after,
  *::before {
    transition-property: color, background-color , fill, border-color, box-shadow;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
  }
`;
