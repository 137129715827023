import { Modal, FlexContainer, Label } from '@facephi/ui-react';
import styled from 'styled-components';

export const StyledModalCreate = styled(Modal)`
  width: 80rem;
  height: 80rem;
`;

export const StyledModalContent = styled(FlexContainer)`
  margin: 2rem;
`;

export const StyledCustomLabel = styled(Label)`
  padding-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--UI--colors-grey100);
`;

export const StyledResourcesContainer = styled(FlexContainer)`
  margin: 1rem 0;
  width: 100%;

  > div {
    flex: 1;
  }
`;
