import {
  CardBase,
  setShadowTheme,
  GridContainer,
  setColorTheme,
} from '@facephi/ui-react';

import styled from 'styled-components';

const MaxColumn = 6;
export const ColumnGap = '2.7';

export const CardApplication = styled(CardBase)<{ active: boolean }>`
  position: relative;
  margin-top: 3rem;
  box-shadow: ${setShadowTheme('medium')};
  border: 1px solid transparent;
  padding: 2.8rem 4rem 2.5rem;
  text-align: center;
  justify-items: center;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.active ? setColorTheme('blue100') : 'white'};

  > p {
    margin-top: 1rem;
  }
`;

export const CardContainer = styled(GridContainer)`
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      max(
        28.8rem,
        calc((100% - calc(${MaxColumn} * ${ColumnGap}rem)) / ${MaxColumn})
      ),
      1fr
    )
  );
  padding: 2rem;
`;
