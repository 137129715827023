import { Button, FlexContainer, Modal, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components';
import { SearchingHeader } from '../searchingHeader';

export const TabSearchingHeader = styled(SearchingHeader)`
  padding: 1.6rem;
`;

export const TenantOptionMenuButton = styled(FlexContainer)`
  button {
    background-color: ${setColorTheme('grey100')};
    border-radius: 100%;
  }
`;

export const ModalCreateTenantStyles = styled(Modal)`
  width: 45rem;

  form {
    display: flex;
    flex: 1;
  }
`;

export const TablePanelMenu = styled(FlexContainer).attrs({
  flexDirection: 'column',
  columnGap: '0.8',
  rowGap: '0.8',
})`
  padding: 1.2rem;
  > * {
    width: min-content;
  }
`;

export const TablePanelButton = styled(Button)`
  padding: 0;
`;
