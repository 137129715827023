import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { listTenants, listTenantsDto, Tenant } from '@cs/state/model';
import {
  addUserTenants,
  makeDefaultTenant,
  removeUserTenants,
} from '@cs/state/mutations';
import { getTenants, getTenantsByUser } from '@cs/state/queries';
import {
  CardBase,
  CardBaseHeader,
  DropdownSearch,
  DropdownSearchOption,
  FlexContainer,
  IconWrapper,
  Label,
  useToast,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CardCollapsableRole } from './CardCollapsableRole';
import { CardRigthUserIdContent } from './Styles';
import { useEmptyState } from '../../hooks/useEmptyState';
import { CardEmptyState } from '../tenantId/Styles';

export const CardRightUserId = () => {
  const { t, i18n } = useTranslation();
  const { toastManager } = useToast();
  const routeParams = useParams();
  const [deleteTenants] = useMutation(removeUserTenants);
  const [insertTenants] = useMutation(addUserTenants);
  const [makeDefault] = useMutation(makeDefaultTenant);

  const { data: tenantsByUser, refetch } = useQuery<listTenants>(
    getTenantsByUser,
    {
      variables: {
        userId: routeParams.userId,
        clientId: routeParams.clientId,
      },
    },
  );

  const tenants = useMemo(
    () => (tenantsByUser ? tenantsByUser.users_tenants : []),
    [tenantsByUser],
  );

  const { data: tenantsByClient } = useQuery<listTenantsDto>(getTenants, {
    notifyOnNetworkStatusChange: true,
    variables: {
      clientId: routeParams.clientId,
      name: '%%',
      offset: 0,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const options: DropdownSearchOption[] = useMemo(() => {
    return tenantsByClient
      ? tenantsByClient?.clients_by_pk
        ? tenantsByClient?.clients_by_pk.tenants.map(({ id, name }) => ({
            name: name,
            value: id,
          }))
        : []
      : [];
  }, [tenantsByClient]);

  const addTenants = (tenantsId: string[]) => {
    return new Promise((resolve, reject) => {
      if (!tenantsId.length) return resolve(false);
      insertTenants({
        variables: {
          tenantsId,
          userId: routeParams.userId,
        },
        refetchQueries: ['getUsersByTenant'],
        onCompleted: () => {
          refetch();
        },
      })
        .catch(() => reject())
        .then(() => resolve(true));
    });
  };

  const removeTenant = (tenantsId: string[], notify = true) => {
    return new Promise((resolve, reject) => {
      if (!tenantsId.length) return resolve(false);

      deleteTenants({
        variables: { tenantsId, userId: routeParams.userId },
        onCompleted: () => {
          refetch();

          if (notify) {
            toastManager({
              type: 'success',
              message: t('Tenant deleted'),
              testId: 'toast-delete-tenant',
            });
          }
        },
      })
        .catch(() => reject())
        .then(() => resolve(true));
    });
  };

  const handleTenants = async (tenantsId: string[]) => {
    const toAdd = tenantsId.filter(
      (id) =>
        !tenantsByUser?.users_tenants.find(({ tenant }) => tenant.id === id),
    );
    const toRemove =
      tenantsByUser?.users_tenants.filter(
        ({ tenant }) => !tenantsId.includes(tenant.id),
      ) || [];

    try {
      const response = await Promise.all([
        addTenants(toAdd),
        removeTenant(
          toRemove?.map(({ tenant }) => tenant.id),
          false,
        ),
      ]);

      if (response.some((result) => result)) {
        toastManager({
          type: 'success',
          message: t('Tenants updated'),
          testId: 'toast-update-user',
        });
      }
    } catch {
      toastManager({
        type: 'error',
        message: t('Something went wrong, try again'),
        testId: 'toast-error-update-user',
      });
    }
  };

  const defaultTenant = async (tenantId: string) => {
    makeDefault({
      variables: {
        tenantId,
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('Tenant marked as default'),
          testId: 'toast-default-tenant',
        });
        refetch();
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          testId: 'toast-error-update-user',
        });
      },
    });
  };

  const { emptyState } = useEmptyState({
    emptyTenants: !tenantsByUser?.users_tenants.length,
  });

  return (
    <CardBase flexDirection="column" flex="1">
      <CardBaseHeader>
        <FlexContainer columnGap="0.8" alignItems="center">
          <IconWrapper iconName="GlobeHemisphereEast" color="yellow" size="M" />
          <Label fontSize="14" semibold>
            {t('Tenant list')}
          </Label>
        </FlexContainer>

        <FlexContainer columnGap="0.4">
          <Label fontSize="14">{`${t('Total assigned')}:`}</Label>

          <Label fontSize="14" semibold color="cyan400">
            {tenantsByUser?.users_tenants.length || 0}
          </Label>
        </FlexContainer>
      </CardBaseHeader>

      <CardRigthUserIdContent flexDirection="column" flex="1">
        <DropdownSearch
          value={tenants.map(({ tenant }) => tenant?.id) || []}
          options={options}
          multiple
          locale={i18n.language}
          showListContent={false}
          placeholder={t('Select tenant and assign to this user')}
          onChange={() => {}}
          onClose={handleTenants}
        />
        {tenants.map(
          ({
            tenant,
            id,
            active,
          }: {
            tenant: Tenant;
            id: string;
            active: boolean;
          }) => (
            <CardCollapsableRole
              key={tenant.id}
              title={tenant.name}
              id={tenant.id}
              onDelete={() => removeTenant([tenant.id])}
              onDefault={() => defaultTenant(id)}
              iconName={active ? 'Star' : undefined}
              active={active}
            />
          ),
        )}
        {!tenants.length && (
          <CardEmptyState
            image={emptyState?.image}
            title={emptyState?.title || ''}
            subtitle={emptyState?.subtitle}
          />
        )}
      </CardRigthUserIdContent>
    </CardBase>
  );
};
