import { FlexContainer, TabsHorizontal } from '@facephi/ui-react';

import styled from 'styled-components';

export const ClientIdWrapper = styled(FlexContainer)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ClientIdTabs = styled(TabsHorizontal)`
  width: 100%;
`;

export const ClientIdTabsWrapper = styled(FlexContainer)`
  height: 100%;
`;
