import { useMemo } from 'react';
import { useCopyToClipboard, useDelete } from '@cs/hooks';
import { APiKeyItemDto, TableProps } from '@cs/state/model';
import { deleteApiKey, undoDeleteApiKey } from '@cs/state/mutations';
import {
  Button,
  ButtonIcon,
  Icon,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  TableLegacy as TableSelection,
  useTimezone,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

type Props = TableProps & {
  apikeys?: APiKeyItemDto[];
  onEdit?(apiKey: APiKeyItemDto): void;
};

export const TableApiKeys = ({ apikeys = [], onEdit, ...rest }: Props) => {
  const { t } = useTranslation();
  const { formatTimezone } = useTimezone();
  const { handleDelete } = useDelete(
    deleteApiKey,
    undoDeleteApiKey,
    'api_keys',
  );
  const { copy } = useCopyToClipboard();

  const columns = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'name',
      },
      {
        Header: t('Date created'),
        accessor: 'created_at',
      },
      {
        Header: t('Code'),
        accessor: 'code',
        Cell: (props: CellProps<APiKeyItemDto>) => {
          return (
            <Button
              variant="text"
              type="button"
              semiBold={false}
              onClick={() => copy(props.row.original.api_key)}
            >
              {props.row.original.api_key}

              <Icon iconName="Copy" size="16" />
            </Button>
          );
        },
      },
      {
        Header: t('Actions'),
        accessor: 'actions',
        Cell: (props: CellProps<APiKeyItemDto>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              widthAuto
              renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
              positionX={PositionX.end}
              testId={`option-menu-${props.row.id}`}
            >
              <OptionMenuItem
                testId="button-view"
                iconName="MagnifyingGlassPlus"
                onClick={() => onEdit && onEdit(props.row.original)}
              >
                {t('View')}
              </OptionMenuItem>
              <OptionMenuItem
                testId="button-delete"
                onClick={() => handleDelete(props.row.original.id)}
                iconName="Trash"
                color="error"
              >
                {t('Delete')}
              </OptionMenuItem>
            </OptionMenu>
          );
        },
        maxWidth: 100,
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    return (
      apikeys.map((item) => ({
        name: item.name,
        created_at: formatTimezone(item.created_at),
        api_key: item.api_key,
        configuration: item.configuration,
        id: item.id,
        api_keys_services: item.api_keys_services,
      })) || []
    );
  }, [apikeys]);

  return <TableSelection columns={columns as any} data={tableData} {...rest} />;
};
