import { useEffect } from 'react';
import { useSearchUser } from '@cs/hooks';
import { ModalAssignUserProps, User } from '@cs/state/model';
import { FlexContainer, Input, Label, OptionMenuItem } from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalCreateUserStyles } from './Styles';
import { EMAIL, FIRSTNAME, LASTNAME, PASSWORD } from '../../state/constants';
import { createUserSchema, addUserSchema } from '../../state/model';

export const ModalAssignUser = ({
  className,
  onSave,
  onChangeShow,
  show,
  clientId,
}: ModalAssignUserProps) => {
  const { t } = useTranslation();
  const { onHandleSearch, resetUsers, users, addUser, setAddUser } =
    useSearchUser(clientId);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(addUser ? createUserSchema : addUserSchema),
    mode: 'onChange',
  });

  const onSubmit = (newUser: User) => {
    onSave(!newUser.password ? { ...newUser, password: '****' } : newUser);
  };

  useEffect(() => {
    if (show) {
      reset({});
    }
  }, [show]);

  return (
    <ModalCreateUserStyles
      show={show}
      testId="modal-create-user"
      header={{
        iconName: 'PlusCircle',
        title: t('Assign or create a user'),
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      onChangeShow={onChangeShow}
      overlayBlur
      className={className}
      isForm
      hasPadding
    >
      <FlexContainer
        as="form"
        flexDirection="column"
        rowGap="1.6"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          autoComplete="off"
          name="hidden"
          type="text"
          style={{ display: 'none' }}
        />
        <Label fontSize="14" htmlFor="email">
          {t('Email')}
        </Label>

        <Input
          type="text"
          placeholder={t('Enter new email or search by email')}
          {...register(EMAIL)}
          autoComplete="off"
          onChange={onHandleSearch}
        />

        {users.length > 0 && (
          <Label fontSize="12" black semibold>
            {t('Select a user from the list')}
          </Label>
        )}

        {users.map((item) => {
          return (
            <OptionMenuItem
              key={item.value}
              iconName={item.icon}
              onClick={(event) => {
                setValue(EMAIL, item.value);
                setValue(FIRSTNAME, item.firstName);
                setValue(LASTNAME, item.lastName);
                setValue(PASSWORD, item.password);
                setAddUser(item.add);
                resetUsers();
                event.preventDefault();
              }}
            >
              {item.name}
            </OptionMenuItem>
          );
        })}

        <Label fontSize="14" htmlFor="firstName">
          {t('Firt Name')}
        </Label>
        <Input
          testId="user-input-firstName"
          id="firstName"
          {...register(FIRSTNAME)}
          readOnly={!addUser}
          errorLabel={errors.firstName?.message && t(errors.firstName.message)}
        />

        <Label fontSize="14" htmlFor="lastName">
          {t('Last Name')}
        </Label>
        <Input
          testId="user-input-lastName"
          id="lastName"
          {...register(LASTNAME)}
          readOnly={!addUser}
          errorLabel={errors.lastName?.message && t(errors.lastName.message)}
        />

        <Label fontSize="14" htmlFor="password">
          {t('Password')}
        </Label>

        <Input
          testId="user-input-password"
          id="input-password"
          type="password"
          autoComplete="new-password"
          {...register(PASSWORD)}
          readOnly={!addUser}
          disabled={!addUser}
          errorLabel={errors.password?.message && t(errors.password.message)}
        />
      </FlexContainer>
    </ModalCreateUserStyles>
  );
};
