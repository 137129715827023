import { useEffect } from 'react';
import { NAME, SLUG } from '@cs/state/constants';
import {
  createTenantSchema,
  ModalCreateTenantProps,
  Tenant,
} from '@cs/state/model';
import { FlexContainer, Input, Label } from '@facephi/ui-react';
import { useSlug } from '@cs/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalCreateTenantStyles } from './Styles';

export const ModalCreateTenant = ({
  className,
  onSave,
  onChangeShow,
  show,
}: ModalCreateTenantProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(createTenantSchema),
    mode: 'onChange',
  });

  const { value } = useSlug({ control, name: 'name' });
  const { value: valueOriginal } = useSlug({ control, name: 'slug' });

  useEffect(() => {
    if (valueOriginal) {
      setValue('slug', valueOriginal);
    }
  }, [valueOriginal]);

  useEffect(() => {
    if (value) {
      setValue('slug', value);
    }
  }, [value]);

  const onSubmit = (newTenant: Tenant) => {
    if (newTenant) {
      onSave(newTenant);
    }
  };

  useEffect(() => {
    if (show) {
      reset({});
    }
  }, [show]);

  return (
    <ModalCreateTenantStyles
      show={show}
      testId="modal-create-tenant"
      onChangeShow={onChangeShow}
      header={{
        iconName: 'PlusCircle',
        title: t('Create a new tenant'),
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      overlayBlur
      className={className}
      hasPadding
    >
      <FlexContainer
        as="form"
        id="form"
        flexDirection="column"
        rowGap="1.6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Label fontSize="14" htmlFor="name">
          {t('Name')}
        </Label>

        <Input
          testId="tenant-input-name"
          autoFocus
          id="name"
          {...register(NAME)}
          errorLabel={errors.name?.message && t(errors.name.message)}
        />

        <Label fontSize="14" htmlFor="name">
          {t('Slug')}
        </Label>

        <Input
          testId="tenant-input-slug"
          autoFocus
          id="slug"
          {...register(SLUG)}
          errorLabel={errors.slug?.message && t(errors.slug.message)}
        />
      </FlexContainer>
    </ModalCreateTenantStyles>
  );
};
