import { lazy, useEffect } from 'react';
import { BrowserNoValidate } from '@cs/components';
import { browserList, RoutesName } from '@cs/state/constants';
import { compare } from 'compare-versions';
import dayjs from 'dayjs';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as ReactRoutes,
} from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';

import 'dayjs/locale/es';
import 'dayjs/locale/en';

const Login = lazy(() => import('@cs/pages/Login'));
const UserIdPage = lazy(() => import('@cs/pages/UserId'));
const ProfilePage = lazy(() => import('@cs/pages/Profile'));
const ClientProfile = lazy(() => import('@cs/pages/ClientProfile'));
const TenantIdPage = lazy(() => import('@cs/pages/TenantId'));
const ClientsPage = lazy(() => import('@cs/pages/Clients'));
const ClientIdPage = lazy(() => import('@cs/pages/ClientId'));
const LicensesByServicePage = lazy(() => import('@cs/pages/LicensesByService'));
const LicensesIdPage = lazy(() => import('@cs/pages/LicensesId'));
const Page404 = lazy(() => import('@cs/pages/Error404'));
const Page500 = lazy(() => import('@cs/pages/Error500'));
const ApiKeysPage = lazy(() => import('@cs/pages/Apikeys'));
const LicenseRequest = lazy(() => import('@cs/pages/LicenseRequest'));
const Requests = lazy(() => import('@cs/pages/Requests'));
const Roles = lazy(() => import('@cs/pages/Roles'));

export const Routes = () => {
  const { isAuthenticated } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    dayjs.locale(i18n.language?.split('-')[0]);
  }, [i18n.language]);

  const validateBrowser = () => {
    const currentBrowser = browserList.find(
      (item) => item.name === browserName,
    );
    return (
      currentBrowser &&
      compare(fullBrowserVersion, currentBrowser.version, '>=')
    );
  };

  if (isAuthenticated === undefined) {
    return null;
  }

  return validateBrowser() ? (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to={RoutesName.clients} />} />
        <Route
          path={RoutesName.apiKeys}
          element={<ProtectedRoute component={ApiKeysPage} />}
        />
        <Route
          path={RoutesName.profile}
          element={<ProtectedRoute component={ProfilePage} />}
        />
        <Route
          path={RoutesName.clients}
          element={<ProtectedRoute component={ClientsPage} />}
        />
        <Route
          path={RoutesName.clientId}
          element={<ProtectedRoute component={ClientIdPage} />}
        />
        <Route
          path={RoutesName.clientProfileId}
          element={<ProtectedRoute component={ClientProfile} />}
        />
        <Route
          path={RoutesName.licensesByService}
          element={<ProtectedRoute component={LicensesByServicePage} />}
        />
        <Route
          path={RoutesName.licenseByService}
          element={<ProtectedRoute component={LicensesIdPage} />}
        />
        <Route
          path={RoutesName.licenseId}
          element={<ProtectedRoute component={LicensesIdPage} />}
        />
        <Route
          path={RoutesName.userId}
          element={<ProtectedRoute component={UserIdPage} />}
        />
        <Route
          path={RoutesName.tenantId}
          element={<ProtectedRoute component={TenantIdPage} />}
        />
        <Route
          path={RoutesName.licenseRequest}
          element={<ProtectedRoute component={LicenseRequest} />}
        />
        <Route
          path={RoutesName.requests}
          element={<ProtectedRoute component={Requests} />}
        />
        <Route
          path={RoutesName.roles}
          element={<ProtectedRoute component={Roles} />}
        />
        <Route path={RoutesName.error} element={<Page500 />} />
        <Route path="*" element={<Page404 />} />
      </ReactRoutes>
    </BrowserRouter>
  ) : (
    <BrowserNoValidate />
  );
};
