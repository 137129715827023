import { gql } from '@apollo/client';

export const getRoles = gql`
  query getRoles($clientId: uuid!, $tenantName: String!) {
    roles: getRoles(clientId: $clientId, tenantName: $tenantName) {
      name
      id
    }
  }
`;

export const getUserRole = gql`
  query getUserRole($tenantId: uuid!, $userId: uuid!) {
    role: users_role(
      where: { tenant_id: { _eq: $tenantId }, user_id: { _eq: $userId } }
    ) {
      role
      role_id
    }
  }
`;

export const getRolesAll = gql`
  query getRolesAll {
    items: roles(order_by: { name: asc }) {
      id
      name
      predefined
      permissions: role_permissions {
        action
        resource
      }
    }
    total: request_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getResources = gql`
  query getResources {
    resources: role_resources {
      name
    }
  }
`;

export const getActionsByResource = gql`
  query getActionsByResource($resource: role_resources_enum!) {
    actions: role_actions_resources(where: { resource: { _eq: $resource } }) {
      name: action
      value: action
    }
  }
`;
