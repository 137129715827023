import {
  FlexContainer,
  Input,
  InputDatePicker,
  setColorTheme,
  TextArea,
} from '@facephi/ui-react';
import styled from 'styled-components';

export const LicenseIdCardList = styled(FlexContainer)`
  flex: 1 1 0;
  overflow-y: auto;
  padding: 2rem 1.4rem;
`;

export const LicenseIdCardContent = styled(LicenseIdCardList)`
  padding: 2rem 1.4rem;
`;

const INPUT_WIDTH = '25rem';

export const LicenseIdInput = styled(Input)`
  width: ${INPUT_WIDTH};
`;

export const LicenseIdInputDatePicker = styled(InputDatePicker)`
  width: ${INPUT_WIDTH};
`;

export const LicenseIdDatePicker = styled(InputDatePicker)`
  width: ${INPUT_WIDTH};
`;

export const LicenseIdTextArea = styled(TextArea)`
  width: ${INPUT_WIDTH};
`;

export const LicenseIdElement = styled(FlexContainer)`
  border-bottom: 1px solid ${setColorTheme('grey50')};
  min-height: 7.5rem;
  padding: 1rem 2rem;
`;
