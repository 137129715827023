import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Tags } from '@cs/state/model';
import { getTags } from '@cs/state/queries';

export function useTags() {
  const { data, loading } = useQuery<{ tags: Tags[] }>(getTags);

  const tags = useMemo(() => (data ? data.tags : []), [data]);

  return {
    tags,
    loading,
  };
}
