import { useState, useEffect, useMemo } from 'react';
import { useRoles } from '@cs/hooks';
import {
  Button,
  ButtonIcon,
  Dropdown,
  FlexContainer,
  Label,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { DropdownAction } from './DropdownAction';
import { StyledResourcesContainer } from './Styles';

export type PermissionsDto = {
  action: string;
  resource: string;
};

type IProps = {
  value: PermissionsDto[];
  onChange(value: PermissionsDto[]): void;
  errorLabel?: string;
};

const defaultValue = { action: '', resource: '' };

export function PermissionsInput({
  value = [defaultValue],
  onChange,
  errorLabel,
}: IProps) {
  const { listResources, resources } = useRoles();
  const { t } = useTranslation();

  useEffect(() => {
    listResources();
  }, []);

  const [innerPermissions, setInnerPermissions] =
    useState<PermissionsDto[]>(value);

  useEffect(() => {
    const filteredPermissions = innerPermissions.filter((item) =>
      resources.some((res) => res.value === item.resource),
    );
    if (filteredPermissions.length) {
      onChange(filteredPermissions);
    }
  }, [innerPermissions]);

  const onSelectResource = (value: string, index: number) => {
    setInnerPermissions((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, resource: value } : item,
      ),
    );
  };

  const checkPermission = (item: PermissionsDto) => {
    if (item.action !== '' && item.resource !== '') {
      return false;
    }
    return true;
  };

  const onSelectAction = (value: string, index: number) => {
    setInnerPermissions((prev) =>
      prev.map((item, i) => (i === index ? { ...item, action: value } : item)),
    );
  };

  const canAddMore = useMemo(() => {
    if (innerPermissions.length === 0) return false;

    const lastItem = innerPermissions[innerPermissions.length - 1];

    const hasResource = lastItem.resource.trim() !== '';
    const hasPermission = lastItem.action.trim() !== '';

    return hasResource && hasPermission;
  }, [innerPermissions]);

  const onAddMore = () => {
    setInnerPermissions((items) => [...items, defaultValue]);
  };

  const onDeletePermission = (index: number) => {
    setInnerPermissions((prev) => prev.filter((_, i) => i !== index));

    if (index === 0 && innerPermissions.length === 1) {
      setInnerPermissions([defaultValue]);
    }
  };

  return (
    <FlexContainer alignItems="flex-start" flexDirection="column" flex="1">
      {innerPermissions.map((item, index) => (
        <StyledResourcesContainer
          flexDirection="row"
          columnGap="2"
          wrap="wrap"
          alignItems="self-end"
          justifyContent="flex-end"
          key={item.resource + item.action}
        >
          <Dropdown
            options={resources}
            label={t('Resource')}
            value={item.resource}
            placeholder={t('Select a resource')}
            onChange={(value) => onSelectResource(value as string, index)}
          />
          <DropdownAction
            value={item.action}
            resource={item.resource}
            onChange={(value) => onSelectAction(value as string, index)}
          />
          <ButtonIcon
            iconName="X"
            variant="secondary"
            disabled={checkPermission(item)}
            onClick={() => onDeletePermission(index)}
          />
        </StyledResourcesContainer>
      ))}
      <Button variant="text" disabled={!canAddMore} onClick={onAddMore}>
        {t('Add more')}
      </Button>

      <Label color="error500" fontSize="12">
        {errorLabel}
      </Label>
    </FlexContainer>
  );
}
