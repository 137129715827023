
import { useMemo } from 'react';
import { useDelete } from '@cs/hooks';
import { RoutesName } from '@cs/state/constants';
import { SCOPES, TablePaginateProps, Tenant } from '@cs/state/model';
import { removeTenant, undoRemoveTenant } from '@cs/state/mutations';
import {
  ButtonIcon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  TablePagination,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PermissionsGate } from '../permissions';
import { TablePanelButton } from './Styles';

type Props = TablePaginateProps & {
  tenants: Tenant[];
};

export const TenantTable = ({
  tenants,
  loading,
  emptyStateData,
  totalPages,
  changePage,
  currentPage,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { handleDelete } = useDelete(removeTenant, undoRemoveTenant, 'tenants');

  const columns = [
    {
      id: 'name',
      header: t('Name'),
      accessorKey: 'name',
      maxWidth: 200,
    },
    {
      id: 'id',
      header: t('ID'),
      accessorKey: 'id',
      maxWidth: 300,
    },
    {
      id: 'slug',
      header: t('Slug'),
      accessorKey: 'slug',
      maxWidth: 200,
    },
    {
      id: 'users',
      header: t('Users'),
      accessorKey: 'tenantUsers',
      cell: (props: any) => {
        const tenant = props.row.original as any;

        return tenant.usersTenants?.length ? (
          <OptionMenu
            renderItem={
              <TablePanelButton
                variant="text"
                iconName="CaretDown"
                iconPosition="right"
              >
                {`${tenant.usersTenants.length} ${t('Users')}`}{' '}
              </TablePanelButton>
            }
          >
            {tenant.usersTenants.map((tenantUser: any) =>
              tenantUser.user ? (
                <OptionMenuItem key={tenantUser.user.id}>
                  {tenantUser.user.name}
                </OptionMenuItem>
              ) : null,
            )}
          </OptionMenu>
        ) : (
          <Label fontSize="14">{t('No users')}</Label>
        );
      },
    },
    {
      id: 'action',
      header: t('Action'),
      accessorKey: 'action',
      maxWidth: 60,
      cell: (props: any) => {
        return (
          <OptionMenu
            hidePanelOnClick
            widthAuto
            renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
            positionX={PositionX.end}
            testId="option-menu-tenant"
          >
            <OptionMenuItem
              testId="button-edit-tenant"
              iconName="MagnifyingGlassPlus"
              onClick={() =>
                navigate(
                  generatePath(RoutesName.tenantId, {
                    clientId: routeParams.id,
                    tenantId: props.row.original.tenantId,
                  }),
                )
              }
            >
              {t('Edit')}
            </OptionMenuItem>

            <PermissionsGate scopes={[SCOPES.canDeleteLicense]}>
              <OptionMenuItem
                testId="button-delete-tenant"
                onClick={() => handleDelete(props.row.original.tenantId)}
                iconName="Trash"
                color="error"
              >
                {t('Delete')}
              </OptionMenuItem>
            </PermissionsGate>
          </OptionMenu>
        );
      },
    },
  ];

  const tableData = useMemo(() => {
    return (
      tenants.map((tenant: Tenant) => ({
        id: tenant.id,
        slug: tenant.slug,
        tenantId: tenant.id,
        name: tenant.name,
        usersTenants: tenant.users_tenants,
      })) || []
    );
  }, [tenants]);

  return (
    <TablePagination
      columns={columns}
      data={tableData}
      loading={loading}
      emptyStateData={emptyStateData}
      onChangePage={changePage}
      totalElements={totalPages}
      currentPage={currentPage}
    />
  );
};
