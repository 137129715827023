import { gql } from '@apollo/client';

export const tenantFragment = gql`
  fragment tenantsFields on tenants {
    name
    slug
    id
    address
    timezone
    tenantId: tenant_id
  }
`;
