import React, { createContext, useState } from 'react';
import { AsideNavItem, Layout, LayoutProps } from '@cs/components';
import { usePermissions } from '@cs/hooks';
import { RoutesName } from '@cs/state/constants';
import { SCOPES } from '@cs/state/model';
import { useTranslation } from 'react-i18next';

type IProps = {
  children: React.ReactNode;
};

type Menu = AsideNavItem & {
  visible?: boolean;
};

type ContextProps = {
  goBackRoute?: LayoutProps['goBackRoute'];
  setGoBackRoute(goBackRoute: LayoutProps['goBackRoute']): void;
  title?: string;
  setTitle(title: string): void;
  leftPanel?: boolean;
};

const Ctx = createContext<ContextProps>({
  setTitle: () => {},
  setGoBackRoute: () => {},
});

export const LayoutProvider = ({ children }: IProps) => {
  const [title, setTitle] = useState<string | undefined>();
  const [goBackRoute, setGoBackRoute] = useState<LayoutProps['goBackRoute']>();

  const { t } = useTranslation();

  const { hasPermission } = usePermissions();

  const menus: Menu[] = [
    {
      icon: 'UserFocus',
      link: RoutesName.clients,
      testId: 'clients',
      id: '1',
      label: t('Clients'),
      visible: true,
    },
    {
      icon: 'UserSwitch',
      link: RoutesName.roles,
      testId: 'roles',
      id: '1',
      label: t('Roles'),
      visible: true,
    },
    {
      icon: 'FileText',
      link: RoutesName.requests,
      testId: 'requests',
      id: '3',
      label: t('Requests'),
      visible: hasPermission([SCOPES.canListRequests]),
    },
    {
      icon: 'Lock',
      link: RoutesName.apiKeys,
      testId: 'apikeys',
      id: '2',
      label: t('ApiKeys'),
      visible: hasPermission([SCOPES.canViewCentralServicesApiKeys]),
    },
  ];

  return (
    <Ctx.Provider
      value={{
        title,
        setTitle,
        goBackRoute,
        setGoBackRoute,
      }}
    >
      <Layout
        menu={menus.filter((menu) => menu.visible)}
        title={title}
        goBackRoute={goBackRoute}
      >
        {children}
      </Layout>
    </Ctx.Provider>
  );
};
export const useLayout = () => React.useContext(Ctx);
