import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ModalCreateTenant,
  TabSearchingHeader,
  TenantTable,
} from '@cs/components';
import { useTenantsPaginate } from '@cs/hooks';
import { useLayout } from '@cs/providers';
import { RoutesName } from '@cs/state/constants';
import { SCOPES, Tenant } from '@cs/state/model';
import { insertTenant } from '@cs/state/mutations';
import { CardBase, useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';

export const Tenants = () => {
  const routeParams = useParams();
  const { t } = useTranslation();
  const { setTitle, setGoBackRoute } = useLayout();
  const { toastManager } = useToast();

  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    tenants,
    total,
    search,
    handleSearch,
    currentPage,
    changePage,
    updateTenants,
    tenantsError,
    loading,
  } = useTenantsPaginate(routeParams.id as string);

  const [createTenant] = useMutation(insertTenant);

  const onCreate = async (tenant: Tenant) => {
    await createTenant({
      variables: {
        clientId: routeParams.id,
        name: tenant.name.toLowerCase(),
        slug: tenant.slug,
        timezone: '',
        address: '',
      },
      onCompleted: () => {
        updateTenants();
        toastManager({
          type: 'success',
          message: t('Tenant successfully created'),
          duration: 3000,
          testId: 'toast-create-tenant',
        });
      },
    });

    setShowModal(false);
  };

  useEffect(() => {
    setTitle(t('Tenants list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  const { emptyState } = useEmptyState({
    data: tenants,
    hasError: Boolean(tenantsError),
    isLoading: loading,
    isFilter: Boolean(search.length),
    onRetry: updateTenants,
  });

  return (
    <>
      <CardBase flexDirection="column" flex="1">
        <TabSearchingHeader
          onCreate={() => setShowModal(true)}
          onChange={handleSearch}
          permissionsCreate={[SCOPES.canWriteTenants]}
        />

        {!loading && (
          <TenantTable
            tenants={tenants}
            loading={loading}
            changePage={changePage}
            totalPages={total}
            currentPage={currentPage}
            emptyStateData={emptyState}
          />
        )}
      </CardBase>

      {showModal && (
        <ModalCreateTenant
          show={showModal}
          onSave={onCreate}
          onChangeShow={(showModalCreateTenant) =>
            setShowModal(showModalCreateTenant)
          }
        />
      )}
    </>
  );
};
