import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { createRoleSchema, RoleDto } from '@cs/state/model';
import { createRole, editRole } from '@cs/state/mutations';
import { Input, Label, useToast } from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsDto, PermissionsInput } from './PermissionsInput';
import {
  StyledModalCreate,
  StyledModalContent,
  StyledCustomLabel,
} from './Styles';

type Inputs = {
  name: string;
  permissions: PermissionsDto[];
};

type IProps = {
  show?: boolean;
  onChangeShow: (show: boolean) => void;
  role?: RoleDto;
};

export const ModalRole = ({ show, onChangeShow, role }: IProps) => {
  const { t } = useTranslation();

  const [insertRole] = useMutation(createRole);
  const [updateRole] = useMutation(editRole);
  const { toastManager } = useToast();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(createRoleSchema),
  });

  useEffect(() => {
    if (role) {
      reset({
        name: role.name,
        permissions: role.permissions.map((item) => ({
          action: item.action,
          resource: item.resource,
        })),
      });
    }
  }, [role]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    const options = {
      variables: role ? { role: { ...data, id: role.id } } : { role: data },
      onCompleted: () => {
        toastManager({
          message: t('Role successfully added'),
          type: 'success',
        });
        onChangeShow(false);
      },
    };

    if (role) {
      await updateRole(options);
    } else {
      await insertRole(options);
    }
  };

  return (
    <StyledModalCreate
      show={show}
      onChangeShow={onChangeShow}
      header={{ title: t(role ? 'Edit role' : 'Create a new role') }}
      footer={{
        closeButtonLabel: t('Close'),
        submitButtonLabel: t('Save'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
    >
      <StyledModalContent flex="1" flexDirection="column">
        <Input
          label={t('Name')}
          {...register('name')}
          placeholder={t('Enter a descriptive name for the role')}
          errorLabel={errors.name?.message && t(errors.name.message)}
        />

        <StyledCustomLabel fontSize="14" semibold>
          {t('Permits')}
        </StyledCustomLabel>

        <Label fontSize="12">
          {t('Select the permissions you want for the role')}
        </Label>

        <Controller
          control={control}
          name="permissions"
          render={({ field, fieldState }) => (
            <PermissionsInput
              {...field}
              {...fieldState}
              errorLabel={errors.permissions && t('Permissions are required')}
            />
          )}
        ></Controller>
      </StyledModalContent>
    </StyledModalCreate>
  );
};
