/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

export type JsonValuesDto = {
  components: any;
  dateEnd: string;
  bundleId: string;
};

export function useLicenseJson(json: string) {
  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState<JsonValuesDto | undefined>();

  const getValidJson = (value: string) => {
    if (!value || value === '') {
      return false;
    }

    try {
      const json = JSON.parse(value);

      const components = extractComponentValue(json, 'components');
      const dateEnd = extractComponentValue(json, 'dateEnd');
      const bundleId = extractComponentValue(json, 'id');

      const items: { [key: string]: any } = {};

      components.map((item: any) => {
        items[item.name] = isJsonString(item.license)
          ? JSON.parse(item.license)
          : item.license;
      });

      setError(false);

      setValues({
        components: items,
        dateEnd: dateEnd.find((item) => item !== 'never'),
        bundleId: bundleId.length ? bundleId[0] : undefined,
      });
    } catch {
      setError(true);
    }
  };

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  }

  const extractComponentValue = (json: any, keyName: string) => {
    let componentsValue: any[] = [];

    function extract(json: any) {
      for (const key in json) {
        if (Object.prototype.hasOwnProperty.call(json, key)) {
          if (key === keyName) {
            componentsValue = componentsValue.concat(json[key]);
          }
          if (typeof json[key] === 'object') {
            extract(json[key]);
          }
        }
      }
    }

    extract(json);
    return componentsValue;
  };

  useEffect(() => {
    getValidJson(json);
  }, [json]);

  return {
    error,
    values,
  };
}
